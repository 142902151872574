import React, { useState } from "react";
import Select from "react-dropdown-select";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import axios from "axios"; // Import axios for API call
import { toast } from "react-toastify";
import styled from "styled-components"; // Import toast for notifications

// styles
import {
  Customemodal,
  Checkbox,
  Checkbox2,
  Customemodalrow,
  Customemodalgroup,
  Customemodalgroup1,
  Contactmodalwrapper,
  Modalbtn,
} from "../assets/style.jsx";

const ContactModal = ({ openFirst, handleModalClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    timeSlot: null,
    message: "",
  });
  const [termsAccepted, setTermsAccepted] = useState({
    term1: false,
    term2: false,
  });
  const StyledInput = styled.input`
  &::placeholder {
    font-family: 'Montserrat', sans-serif;
  }

  &::-webkit-input-placeholder {
    font-family: 'Montserrat', sans-serif;
  }

  &::-moz-placeholder {
    font-family: 'Montserrat', sans-serif;
  
  }

  &:-ms-input-placeholder {
    font-family: 'Montserrat', sans-serif;
  }

  &:-moz-placeholder {
    font-family: 'Montserrat', sans-serif;
  }
`;
  const [errors, setErrors] = useState({});

  const options = [
    { value: "8:00-11:00 Uhr", label: "8:00-11:00 Uhr" },
    { value: "11:00-15:00 Uhr", label: "11:00-15:00 Uhr" },
    { value: "15:00-17:00 Uhr", label: "15:00-17:00 Uhr" }, 
    { value: "17:00-19:00 Uhr", label: "17:00-19:00 Uhr"},
    { value: "19:00-21:00 Uhr", label: "19:00-21:00 Uhr"}
  ];

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSelectChange = (selectedValues) => {
    // Assuming the select component returns an array of selected options
    const timeSlotValue = selectedValues[0] ? selectedValues[0].value : '';
    setFormData({ ...formData, timeSlot: timeSlotValue });
  };

  

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setTermsAccepted({ ...termsAccepted, [name]: checked });
    // Clear errors for that checkbox
   
    setErrors({ ...errors, [name]: "" });
  };
  const validateForm = () => {
    let newErrors = {};
    let formIsValid = true;

    // Name validation
    if (!formData.name.trim()) {
      formIsValid = false;
      newErrors["name"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }

    // Phone number validation
    if (!formData.phoneNumber.trim()) {
      formIsValid = false;
      newErrors["phoneNumber"] = "Bitte überprüfen Sie Ihre Eingabe.";
    } else if (!/^\d*$/.test(formData.phoneNumber)) {
      // Regex for only numbers
      formIsValid = false;
      newErrors["phoneNumber"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }

    // Email validation
    if (!formData.email.trim()) {
      formIsValid = false;
      newErrors["email"] = "Bitte überprüfen Sie Ihre Eingabe.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formIsValid = false;
      newErrors["email"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }

    // Time slot validation
    if (!formData.timeSlot) {
      formIsValid = false;
      newErrors["timeSlot"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }

    // Terms acceptance validation
    if (!termsAccepted.term1) {
      formIsValid = false;
      newErrors["term1"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }
    if (!termsAccepted.term2) {
      formIsValid = false;
      newErrors["term2"] = "Bitte überprüfen Sie Ihre Eingabe.";
    }

    setErrors(newErrors);
    return formIsValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form from causing a page reload
    if (validateForm()) {
      // Constructing the body with form data
      let body = {
        to:"raphael@wickerpartner.de", // Replace with your email address
        name: formData.name,
        email: formData.email,
        telephoneNumber: formData.phoneNumber,
        time: formData.timeSlot,
        description: formData.message,
      };
  
      axios
        .post('https://vast-bastion-64184-6b8b786290c5.herokuapp.com/success/send', body)
        .then((response) => {
          toast.success(`Vielen Dank für die Bewerbung bei der FE Group!
          Wir melden uns so bald wie möglich.
          Weiterleitung zur Startseite.`);
          // Resetting form data and terms state after successful submission
          setFormData({
            name: "",
            phoneNumber: "",
            email: "",
            timeSlot: null,
            message: "",
          });
          setTermsAccepted({
            term1: false,
            term2: false,
          });
          handleModalClose(); // Close the modal upon successful submission
        })
        .catch((error) => {
          toast.error("Etwas ist schief gelaufen. Bitte versuchen Sie es später erneut.");
        });
    } else {
      toast.error("Bitte korrigieren Sie die Fehler im Formular.");
    }
  };
  
  
 
  return (
    <>
      <Contactmodalwrapper>
        <Modal open={openFirst} onClose={handleModalClose} center>
          <Customemodal id="rioni">
            <h2>KONTAKT</h2>
            <Customemodalrow>
            <Customemodalgroup>
             <p>Name*</p>
             <input
    type="text"
    name="name" // Ensure the name attribute matches the formData state key
    className="form-control"
    placeholder="Vor-und Nachname"
    value={formData.name} // Bind input value to formData state
    onChange={handleInputChange} // Update formData state on input change
  />
  {/* Display validation error for the name field if it exists */}
  {errors.name && <div  style={{ color: 'red' }} className="validation-error">{errors.name}</div>}
</Customemodalgroup>
<Customemodalgroup>
<div style={{ display: 'flex', flexDirection: 'row', gap:"10px" }}>
               <p>
               Ihre Erreichbarkeit
                </p>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path
                        d="M9.99984 1.6665C8.35166 1.6665 6.7405 2.15525 5.37009 3.07092C3.99968 3.9866 2.93158 5.28809 2.30084 6.81081C1.67011 8.33353 1.50509 10.0091 1.82663 11.6256C2.14817 13.2421 2.94185 14.727 4.10728 15.8924C5.27272 17.0578 6.75758 17.8515 8.37409 18.173C9.9906 18.4946 11.6662 18.3296 13.1889 17.6988C14.7116 17.0681 16.0131 16 16.9288 14.6296C17.8444 13.2592 18.3332 11.648 18.3332 9.99984C18.3332 8.90549 18.1176 7.82186 17.6988 6.81081C17.28 5.79976 16.6662 4.8811 15.8924 4.10728C15.1186 3.33346 14.1999 2.71963 13.1889 2.30084C12.1778 1.88205 11.0942 1.6665 9.99984 1.6665ZM10.8332 13.3332C10.8332 13.5542 10.7454 13.7661 10.5891 13.9224C10.4328 14.0787 10.2209 14.1665 9.99984 14.1665C9.77883 14.1665 9.56687 14.0787 9.41058 13.9224C9.2543 13.7661 9.16651 13.5542 9.16651 13.3332V9.1665C9.16651 8.94549 9.2543 8.73353 9.41058 8.57725C9.56687 8.42097 9.77883 8.33317 9.99984 8.33317C10.2209 8.33317 10.4328 8.42097 10.5891 8.57725C10.7454 8.73353 10.8332 8.94549 10.8332 9.1665V13.3332ZM9.99984 7.49984C9.83502 7.49984 9.67391 7.45096 9.53686 7.3594C9.39982 7.26783 9.29301 7.13768 9.22994 6.98541C9.16687 6.83314 9.15036 6.66558 9.18252 6.50393C9.21467 6.34228 9.29404 6.19379 9.41058 6.07725C9.52713 5.96071 9.67561 5.88134 9.83727 5.84918C9.99892 5.81703 10.1665 5.83353 10.3187 5.8966C10.471 5.95968 10.6012 6.06649 10.6927 6.20353C10.7843 6.34057 10.8332 6.50169 10.8332 6.6665C10.8332 6.88752 10.7454 7.09948 10.5891 7.25576C10.4328 7.41204 10.2209 7.49984 9.99984 7.49984Z"
                        fill="#808080"
                      />
                    </svg>{" "}
                    </div>
                <Select
    options={options} // Assuming this is defined elsewhere in your component
    values={[]} 
    placeholder="Auswählen"// You need to control the selected value as part of the form state
    onChange={(values) => handleSelectChange(values)} // Handle change to update state
    name="timeSlot"
    styles={{
      placeholder: (provided) => ({
        ...provided,
        fontFamily: 'montserrat',
      }),
    }} // Adding a name for consistency, might not be used directly
  />
   {errors.timeSlot && <div  style={{ color: 'red' }} className="validation-error">{errors.timeSlot}</div>}
 </Customemodalgroup>
            </Customemodalrow>
            <Customemodalrow>
           

<Customemodalgroup className="form-group">
  <p>Telefonnummer*</p>
  <input
    type="text"
    name="phoneNumber" // Make sure the name attribute matches the formData state key for the phone number
    className="form-control"
    placeholder="Telefonnummer"
    value={formData.phoneNumber} // Bind input value to formData state for the phone number
    onChange={handleInputChange} // Update formData state on input change
  />
     {errors.phoneNumber && <div  style={{ color: 'red' }} className="validation-error">{errors.phoneNumber}</div>}

</Customemodalgroup>
<Customemodalgroup>
  <p>E-Mail</p>
  <input
  type="text"
  name="email"
  className="form-control"
  placeholder="Ihre Mailadresse"
  value={formData.email}
  onChange={handleInputChange}
/>
  {/* Display validation error for the email field if it exists */}
  {errors.email && <div style={{ color: 'red' }} className="validation-error">{errors.email}</div>}
</Customemodalgroup>

            </Customemodalrow>
            <Customemodalrow>
            

            </Customemodalrow>
            <Customemodalrow>
            <Customemodalgroup1>
            <p style={{fontFamily: 'Montserrat, sans-serif'}}>Nachricht - optional</p>
  <textarea
    name="message" // Assigning a unique identifier, facilitating targeted data handling.
    className="text_control"
    rows="5"
    cols="50"
    placeholder="Nachricht Hier können Sie uns zusätzliche Anliegen schildern oder Fragen stellen."
    value={formData.message} // Binding the textarea to a state variable, ensuring real-time synchronization of data.
    onChange={handleInputChange} 
    style={{fontFamily: 'Montserrat, sans-serif'}}// Implementing a change handler to update state, reflecting the dynamic nature of user interaction.
  ></textarea>
  {/* Providing immediate, contextual feedback on validation errors, enhancing the communicative feedback loop. */}
  {errors.message && <div style={{ color: 'red' }} className="validation-error">{errors.message}</div>}
</Customemodalgroup1>

            </Customemodalrow>
            <Customemodalrow>
            <Customemodalgroup1>
  <div className="modalcheckbox">
    <Checkbox2>
      <label className="checkBox"> {/* Corrected `class` to `className` */}
      Ich stimme einer Kontaltaufnahme durch einen Berater in meiner Nähe zu und bin damit einverstanden, dass meine Daten für diesen Zweck genutzt werden.        <input
          type="checkbox"
          name="term1" // Assign a name for identification in the form state.
          checked={termsAccepted.term1} // Control checked state with React state.
          onChange={handleCheckboxChange} // Update state on change.
        />
        <span className="checkmark"></span>
      </label>
    </Checkbox2>
    {errors.term1 && <div style={{ color: 'red' }} className="validation-error">{errors.term1}</div>}
  </div>
</Customemodalgroup1>
            </Customemodalrow>
            <Customemodalrow>
            <Customemodalgroup1>
  <div className="modalcheckbox">
    <Checkbox2>
      <label className="checkBox">
      Ich habe die AGB und Datensechutzbestimmungen gelesen und akzeptiert.
        <input
          type="checkbox"
          name="term2"
          checked={termsAccepted.term2}
          onChange={handleCheckboxChange}
          className={termsAccepted.term2 ? 'checked' : ''}
        />
        <span  className="checkmark"></span>
      </label>
    </Checkbox2>
    {errors.term2 && <div style={{ color: 'red',fontSize:"16px" }} className="validation-error">{errors.term2}</div>}
  </div>
</Customemodalgroup1>
            </Customemodalrow>
            <Modalbtn onClick={handleSubmit}>Absenden</Modalbtn>

          </Customemodal>
        </Modal>
      </Contactmodalwrapper>
    </>
  );
};

export default ContactModal;
