import React, { useState } from "react";
import ContactModal from "../../components/contactmodal";
import Header from "../../components/header";
import OwlCarouselSlider from "../../components/owlcarouselslider";
import Faqaccordian from "../../components/accordian";
import "../../assets/common-style.css";
import Footer from "../../components/footer";
import Serviceowlslider from "../../components/serviceowlslider";

import {
  MainIndexpage,
  Container,
  Row,
  Column,
  Bannerheading,
  Topbannerimg,
  Bannerleftcontent,
  Bannerrightcontent,
  Headerbanner,
  Aboutwrapper,
  Aboutheading,
  Innovationbanner,
  Innovationcontent,
  Companyblog,
  Companylogo,
  Copanyblogcontent,
  Borderline,
  Teamsection,
  Teamcard,
  Teamcardcontent,
  Teamdiscription,
  Slidersection,
  Quotesection,
  Faqwrapper,
  Faqheader,
  Bindingwrapper,
  Bindingcontent,
  Containerfluid,
  Mobileview,
  Desktopview,
  MobilebannerContent,
  TeamContainer,
  Member,
   Title,
   Description,
   Role,
} from "../../assets/style";
// images
import headerbnnerImg from "../../assets/images/topBanner-img.png";
import logoimage from "../../assets/images/logologo.svg";
import Teamimg01 from "../../assets/images/team2.svg";
import Teamimg02 from "../../assets/images/team3.svg";
import Teamimg03 from "../../assets/images/team-img-0.svg";
import QuoteImg from "../../assets/images/quoteImg.svg";
import Rightarrow from "../../assets/images/right_arrowBtn.png";

const Home = () => {
  const [openFirst, setOpenFirst] = useState(false);
  const handleModalClose = () => {
    setOpenFirst(false);
  };
  const teamMembers = [
    {
      name: 'Yasmin Wicker',
      role: 'LEITUNG SÜD',
      image: Teamimg03, 
      
      description: [
        'Das starke Fundament von Wicker & Partner mit mehr als 30 Jahren Erfahrung in den Bereichen Bauprojektierungen, Gewerbeimmobilien & Maklergeschäft.',
        'Mit über 300 vermittelten Immobilien in den letzten 10 Jahren und der umfassenden Kenntnis des Immobilienmarktes ist Yasmin unser Allrounder, der auf jede Frage die passende Antwort parat hat.'
      ]    },
    {
      name: 'Raphael Wicker',
      role: 'GESCHÄFTSFÜHRER',
      image:Teamimg01, 
      description: [
        'Unser geschäftsführender Partner der zweiten Generation, hat Betriebswirtschaftslehre und Immobilienwirtschaft studiert.',
        'Seine fundierte Expertise aus den Bereichen Asset- und Risikomanagement sowie seine innovative Herangehensweise prägen maßgeblich den Erfolg von Wicker & Partner im nachhaltigen Aufbau von Privatvermögen durch intelligente Immobilienlösungen.'
      ]
    },
    {
      name: 'Nele Engfer',
      role: 'LEITUNG NORD',
      image: Teamimg02, 
      description: [
        'Nele Engfer verkörpert das Herzstück unserer Unternehmensphilosophie: Professionalität, Integrität und eine unerschütterliche Hingabe für die finanziellen Ziele unserer Kunden.',
        'Mit jahrelanger Erfahrung im Bereich Kapitalanlagen und einem tiefen Verständnis für den Immobilienmarkt leitet Nele das Team Nord mit einer beeindruckenden Vision und einem unfehlbaren Engagement.'
      ]    }
  ];

  return (
    <>
      <Header />
      <MainIndexpage>
        {/* banner section start  */}
        <Headerbanner>
          <Container>
            <Bannerheading>
              <h2> Nachhaltiger  <br />Vermögensaufbau  <br />mit Immobilien </h2>
            </Bannerheading>
            <Desktopview>
              <Row className="banner_alignment">
                <Column>
                  <Bannerleftcontent>
                    <p>
                      Wicker <span>&amp;</span> Partner <span>-</span> Seit zwei
                      Generationen <br /> <strong> Ihr Partner </strong> im
                      nachhaltigen Aufbau von Privatvermögen durch innovative
                      Immobilienlösungen.
                    </p>
                  </Bannerleftcontent>
                </Column>
                <Column>
                  <Topbannerimg>
                    <img src={headerbnnerImg} />
                  </Topbannerimg>
                </Column>
                <Column>
                  <Bannerrightcontent>
                    <p>Generationen verbinden, <br /> Vermögen gestalten.</p>
                  </Bannerrightcontent>
                </Column>
              </Row>
            </Desktopview>
            <Mobileview>
              <Topbannerimg>
                <img src={headerbnnerImg} />
              </Topbannerimg>
              <MobilebannerContent>
                <Row className="justif_content">
                  <Column>
                    <Bannerleftcontent>
                      <p>
                        Wicker <span>&</span> Partner <span>-</span> Seit zwei
                        Generationen <strong> Ihr Partner </strong> im
                        nachhaltigen Aufbau von Privatvermögen durch innovative
                        Immobilienlösungen.
                      </p>
                    </Bannerleftcontent>
                  </Column>
                  <Column>
                    <Bannerrightcontent>
                      <p>Mit Immobilien Werte schaffen.</p>
                    </Bannerrightcontent>
                  </Column>
                </Row>
              </MobilebannerContent>
            </Mobileview>
          </Container>
        </Headerbanner>
        {/* banner section end  */}
        {/* about section start  */}
        <Aboutwrapper>
          <Containerfluid>
            <Row className="align-item-center">
              <Column className="col-md-6 col-12">
                <Aboutheading>
                  <h3> Ablauf </h3>
                  <p>
                    Mit mehr als 50 Jahren Erfahrung im Aufbau von
                    Privatvermögen durch Immobilien, bietet der familiäre
                    Betrieb Wicker <span>&</span> Partner individuelle Lösungen.
                  </p>
                  <p>
                    Nach einer gründlichen Finanzanalyse erfolgt die Auswahl der
                    Objekte und eine umfassende Risikobewertung.
                  </p>
                  <p>
                    Lassen Sie sich gerne unverbindlich und individuell von uns
                    beraten.
                  </p>
                  <button onClick={() => setOpenFirst(true)}>Beratung</button>
                </Aboutheading>
              </Column>
              <Column className="col-md-6 col-12">
                <Serviceowlslider />
              </Column>
            </Row>
          </Containerfluid>
        </Aboutwrapper>
        {/* about section end  */}
        {/* partner innovation section start  */}
        <Innovationbanner className="innovation_bg">
          <Container>
            <Innovationcontent>
              <p> Tradition trifft Innovation </p>
              <h3>
                Wicker <span> & </span> Partner <span> - </span> Ihr Weg zum
                nachhaltigen Vermögensaufbau durch intelligente und individuelle
                Immobilienlösungen.
              </h3>
              <button onClick={() => setOpenFirst(true)}>Beratung</button>
            </Innovationcontent>
          </Container>
        </Innovationbanner>
        {/* partner innovation section end  */}
        {/* company blog section start  */}
        <Companyblog>
          <Container>
            <Row className="align-item-center no-wrape">
              <Column className=" blog_img ">
                <Companylogo>
                  <img src={logoimage} />
                </Companylogo>
              </Column>
              <Column className="blog_content">
                <Copanyblogcontent>
                  <p>
                    In einer Welt, die ständigen Veränderungen unterworfen ist,<br />
                    bleiben wir unserer Tradition treu, während wir gleichzeitig <br />
                    innovativ auf neue Herausforderungen reagieren.
                  </p>
                  <p>
                    Mit einem klaren Blick für Chancen und einem festen <br />
                    Engagement für Qualität setzen wir Maßstäbe <br />im Aufbau von
                    Privatvermögen durch Immobilieninvestitionen.
                  </p>
                </Copanyblogcontent>
              </Column>
            </Row>
            <Row>
              <Column className="col-12">
                <Borderline>
                  <hr />
                </Borderline>
              </Column>
            </Row>
          </Container>
        </Companyblog>
        <TeamContainer>
  {teamMembers.map(member => (
    <Member key={member.name}>
      <img src={member.image} alt={member.name} />
      <Role>{member.role}</Role>
      <Title>{member.name}</Title>
      <Description>
        {member.description[0]}<br />{member.description[1]}
      </Description>
    </Member>
  ))}
</TeamContainer>
        {/* company blog section end  */}
        {/* <Teamsection id="teamSection">
          <Container>
          
              <Column className="col-md-8 col-12">
                <Row>
                  <Column className="col-md-6 col-12 teaminnerColumn_02">
                    <Teamcard>
                      <img src={Teamimg03} alt="team image" />
                      <Teamcardcontent>
                        <h4> LEITUNG SÜD</h4>
                        <h3> Yasmin Wicker </h3>
                        <p>
                        Das starke Fundament von Wicker & Partner mit mehr als 30 Jahren Erfahrung in den Bereichen Bauprojektierungen, Gewerbeimmobilien & Maklergeschäft. 
                        </p>
                        <p>
                        Mit über 300 vermittelten Immobilien in den letzten 10 Jahren und der umfassenden Kenntnis des Immobilienmarktes ist Yasmin unser Allrounder, der auf jede Frage die passende Antwort parat hat. 
                        </p>
                      </Teamcardcontent>
                    </Teamcard>
                  </Column>
                  <Column className="col-md-6 col-12 teaminnerColumn_02">
                    <Teamcard>
                      <img src={Teamimg01} alt="team image" />
                      <Teamcardcontent>
                        <h4> GESCHÄFTSFÜHRER </h4>
                        <h3> Raphael Wicker </h3>
                        <p>
                          Raphael Wicker, unser geschäftsführender Partner der
                          zweiten Generation, Master in Immobilienwirtschaft.
                        </p>
                        <p>
                          Seine fundierte Expertise und innovative
                          Herangehensweise prägen maßgeblich den Erfolg von
                          Wicker <span>&</span> Partner im nachhaltigen Aufbau
                          von Privatvermögen durch intelligente
                          Immobilienlösungen.
                        </p>
                      </Teamcardcontent>
                    </Teamcard>
                  </Column>
                  
                  <Column className="col-md-6 col-12  teaminnerColumn_02">
                    <Teamcard>
                      <img src={Teamimg02} alt="team image" />
                      <Teamcardcontent>
                        <h4> LEITUNG NORD </h4>
                        <h3> Nele Engfer </h3>
                        <p>
                          Nele Engfer verkörpert das Herzstück unserer
                          Unternehmensphilosophie: Professionalität, Integrität
                          und eine unerschütterliche Hingabe für Ihre
                          finanziellen Ziele. Mit jahrelanger Erfahrung im
                          Bereich Kapitalanlagen und einem tiefen Verständnis
                          für den Immobilienmarkt leitet Nele das Team Nord mit
                          einer beeindruckenden Vision und einem unfehlbaren
                          Engagement.
                        </p>
                      </Teamcardcontent>
                    </Teamcard>
                  </Column>
                  
                </Row>
              </Column>
              {/* <Column className="col-md-5 col-12">
                <Teamdiscription>
                  <p>
                    Wicker <span> & </span> Partner, als familiäres Unternehmen
                    mit mehr als zwei Generationen Erfahrung im Aufbau von
                    Privatvermögen durch Immobilien, bieten wir unseren Kunden
                    umfassende Lösungen.
                  </p>
                  <p>
                    Unser tiefes Verständnis für den Immobilienmarkt und unsere
                    langfristige Vision für Erfolg ermöglichen es uns, gemeinsam
                    mit unseren Kunden maßgeschneiderte Strategien zu
                    entwickeln.
                  </p>
                  <p>
                    Wir legen großen Wert auf persönliche Betreuung und setzen
                    dabei höchsten Fokus auf Transparenz und Integrität. Unsere
                    Expertise erstreckt sich über sämtliche Facetten der
                    Immobilienbranche von der Auswahl vielversprechender
                    Investitionsobjekte über die effektive Verwaltung von
                    Bestandsimmobilien bis hin zur Entwicklung zukunftsweisender
                    Projekte.
                  </p>
                  <p>
                    Durch unsere ganzheitlichen Lösungsansätze schaffen wir
                    nicht nur Werte, sondern bauen auch nachhaltige
                    Vermögensportfolios auf.
                  </p>
                  <p>
                    Unsere familiäre Prägung spiegelt sich in einem engen
                    Zusammenhalt und einer starken Kundenbindung wider. Die
                    Werte, die über Generationen hinweg gepflegt wurden,
                    manifestieren sich in unserem ethischen Geschäftsansatz und
                    unserem Streben nach Exzellenz.
                  </p>
                </Teamdiscription>
              </Column> */}
{/*           
          </Container>
        </Teamsection> */} 
        <Slidersection>
          <Container>
            <Row>
              <Column>
                <Quotesection>
                  <img src={QuoteImg} />
                  <p>
                    Durch die präzise Auswahl vielversprechender 
                    Investitionsobjekte und eine durchdachte Umsetzung haben wir 
                    nicht nur Werte geschaffen, sondern auch den Erfolg unserer
                    Mandanten maßgeblich beeinflusst.{" "}
                  </p>
                </Quotesection>
              </Column>
            </Row>
          </Container>
          <OwlCarouselSlider />
        </Slidersection>
        <Faqwrapper>
          <Faqheader>
            <h2>
              Warum Wicker <span>&</span> Partner?
            </h2>
            <p>
            Unser Team von Experten arbeitet kontinuierlich daran, innovative Lösungen zu finden und die Erwartungen unserer Mandanten zu übertreffen. Unsere Erfolge sind das Ergebnis einer engen Zusammenarbeit mit unseren Mandanten, transparenten Kommunikation und dem Streben nach höchster Qualität in jedem Schritt des Projektzyklus.
            </p>
            <p>Diese Fragen beschäftigen unsere Mandanten:</p>
          </Faqheader>
          <Faqaccordian />
        </Faqwrapper>
        <Bindingwrapper onClick={() => setOpenFirst(true)}>
          <Bindingcontent>
            {/* <p>unverbindliche Beratung</p> */}
            <p>
            unverbindliche Beratung
            </p>
            <img src={Rightarrow} />
          </Bindingcontent>
        </Bindingwrapper>

        <Footer />
      </MainIndexpage>
      {openFirst && (
        <ContactModal
          openFirst={openFirst}
          handleModalClose={handleModalClose}
        />
      )}
    </>
  );
};

// const MainIndexpage = styled.div``;

export default Home;
