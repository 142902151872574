import logo from './logo.svg'
import './App.css'
import Home from './pages/home/home'

function App() {
  return (
    <>
      <Home />
    </>
  );
}


export default App;
